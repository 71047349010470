/* eslint-disable-next-line */
import API_CONF from './api_constants'
/**
 * @module _constants
 * @description list of constants enums used troughout the app
 */

const config = {
	/**
	 * @name MAP
	 * @readonly
	 * @property {String} URL - default map tiles
	 * @property {String} URL_ATTRIBUTION - credits to the author of the map
	 * @property {String} URL_DARK - default map tiles, dark mode
	 */
	MAIN_URL_WEBSITE: 'https://www.qomon.com?from=webapp',
	MAP: {
		URL: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
		URL_ATTRIBUTION:
			'<a class="text-xs text-gray-500 text-white" target="_blank" href="https://qomon.com">&copy; Qomon</a> | <a class="text-xs text-gray-500 text-white" target="_blank" href="https://osm.org/copyright">&copy; OpenStreetMap contributors</a>',
		URL_SATELLITE: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=UfM81lef4eNJi3nbUOQ7',
		URL_SATELLITE_ATTRIBUTION:
			'<a class="text-xs text-gray-500 text-white" target="_blank" href="https://qomon.com">&copy; Qomon</a> | <a class="text-xs text-gray-500 text-white" href="https://maptiler.com/copyright/" target="_blank">© MapTiler</a>',
		URL_DARK: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png',
		URL_DARK_ATTRIBUTION:
			'<a class="text-xs text-gray-500 text-white" target="_blank" href="https://qomon.com">&copy; Qomon</a> | <a class="text-xs text-gray-500 text-white" target="_blank" href="https://osm.org/copyright">&copy; OpenStreetMap contributors</a>',
	},
	// eslint-disable-next-line no-warning-comments
	/**
	 * @name ACTION
	 * @readonly
	 * @enum {Object}
	 *
	 * @property {Object} STATUS
	 * @property {String} STATUS.TODO=todo
	 * @property {String} STATUS.DONE=done
	 * @property {String} STATUS.CREATED=created
	 * @property {String} STATUS.DELETED=deleted
	 * @property {String} STATUS.FINISHED=finished
	 * @property {String} STATUS.ARCHIVED=archived
	 * @property {String} STATUS.INPROGRESS=inprogress
	 *
	 * @property {Object} TYPES
	 * @property {String} TYPES.CALL=call
	 * @property {String} TYPES.MAIL=mail
	 * @property {String} TYPES.EVENT=event
	 * @property {String} TYPES.OTHER=other
	 * @property {String} TYPES.POSTER=poster
	 * @property {String} TYPES.STATIC=static
	 * @property {String} TYPES.LEAFLET=leaflet
	 * @property {String} TYPES.CANVASSING=canvassing
	 * @property {String} TYPES.GOTVCANVASSING=gotvcanvassing
	 * @property {String} TYPES.GOTVCALLING=gotvcalling
	 *
	 * @property {Object} COLORS
	 * @property {String} COLORS.CALL=#f6aa3a
	 * @property {String} COLORS.MAIL=#8255f5
	 * @property {String} COLORS.EVENT=#383a3b
	 * @property {String} COLORS.OTHER=#34a6fb
	 * @property {String} COLORS.POSTER=#ee564a
	 * @property {String} COLORS.STATIC=#8255f5
	 * @property {String} COLORS.LEAFLET=#1d2c8d
	 * @property {String} COLORS.CANVASSING=#00d19c
	 * @property {String} COLORS.GOTVCANVASSING=#FBF8F6
	 * @property {String} COLORS.GOTVCALLING=#FBF8F6
	 */
	ACTION: {
		WITH_MEETING_POINT: ['canvassing', 'event', 'gotvcanvassing', 'challenge', 'static', 'mail', 'other'],
		STATUS: {
			TODO: 'todo',
			DONE: 'done',
			CREATED: 'created',
			DELETED: 'deleted',
			FINISHED: 'finished',
			ARCHIVED: 'archived',
			INPROGRESS: 'inprogress',
		},
		TYPES: {
			CANVASSING: 'canvassing',
			CALL: 'call',
			MAIL: 'mail',
			EVENT: 'event',
			STATIC: 'static',
			SHARING: 'sharing',
			REPORTING: 'reporting',
			OTHER: 'other',
			// CHALLENGE: 'challenge',
			// LEAFLET: 'leaflet',
			GOTVCANVASSING: 'gotvcanvassing',
			GOTVCALLING: 'gotvcalling',
		},
		ONLINE_TYPES: {
			CALL: { label: 'call', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.CALL' },
			EVENT: { label: 'event', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.EVENT' },
			SHARING: { label: 'sharing', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.SHARING' },
			OTHER: { label: 'other', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.OTHER' },
			GOTVCALLING: { label: 'gotvcalling', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.GOTVCALLING' },
		},
		OFFLINE_TYPES: {
			CANVASSING: { label: 'canvassing', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.CANVASSING' },
			MAIL: { label: 'mail', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.MAIL' },
			EVENT: { label: 'event', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.EVENT' },
			STATIC: { label: 'static', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.STATIC' },
			OTHER: { label: 'other', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.OTHER' },
			GOTVCANVASSING: { label: 'gotvcanvassing', desc: 'ACTION.CALL_TO_ACTION.INFORMATIONS.TYPES_CARD_DESC.GOTVCANVASSING' },
		},
		ACTUAL_TYPES: {
			CALL: 'call',
			MAIL: 'mail',
			EVENT: 'event',
			OTHER: 'other',
			STATIC: 'static',
			SHARING: 'sharing',
			CANVASSING: 'canvassing',
			REPORTING: 'reporting',
			GOTVCANVASSING: 'gotvcanvassing',
			GOTVCALLING: 'gotvcalling',
		},
		ACTUAL_ONLINE_TYPES: {
			CALL: 'call',
			EVENT: 'event',
			SHARING: 'sharing',
			OTHER: 'other',
			GOTVCALLING: 'gotvcalling',
		},
		ACTUAL_OFFLINE_TYPES: {
			CANVASSING: 'canvassing',
			MAIL: 'mail',
			EVENT: 'event',
			STATIC: 'static',
			OTHER: 'other',
			GOTVCANVASSING: 'gotvcanvassing',
		},
		COLORS: {
			MAIN: {
				CANVASSING: '#039C5E',
				REPORTING: '#c5c9f5',
				EVENT: '#c3900a',
				LEAFLET: '#fbf8f6',
				POSTER: '#039C5E',
				CHALLENGE: '#9e6fd4',
				CALL: '#94a0ff',
				MAIL: '#ff6098',
				STATIC: '#9e6fd4',
				OTHER: '#FF377F',
				SHARING: '#46c9f4',
				GOTVCALLING: '#94a0ff',
				GOTVCANVASSING: '#039C5E',
			},
			SECONDARY: {
				CANVASSING: '#6ed39d',
				REPORTING: '#c5c9f5',
				EVENT: '#ffe383',
				LEAFLET: '#fbf8f6',
				POSTER: '#6ed39d',
				CHALLENGE: '#decef1',
				CALL: '#c5c9f5',
				MAIL: '#ffc6e7',
				STATIC: '#decef1',
				OTHER: '#ffc6e7',
				SHARING: '#8cd8f2',
				GOTVCALLING: '#c5c9f5',
				GOTVCANVASSING: '#6ed39d',
			},
			THIRD: {
				CANVASSING: '#05b66f',
				REPORTING: '#c5c9f5',
				EVENT: '#fbd665',
				LEAFLET: '#fbf8f6',
				POSTER: '#05b66f',
				CHALLENGE: '#d6c2ed',
				CALL: '#94a0ff',
				MAIL: '#ff9bc9',
				STATIC: '#d6c2ed',
				OTHER: '#ff387f',
				SHARING: '#e5f8ff',
				GOTVCALLING: '#94a0ff',
				GOTVCANVASSING: '#05b66f',
			},
			FOURTH: {
				CANVASSING: '#e1f6eb',
				REPORTING: '#f0f1fc',
				EVENT: '#fdeec1',
				LEAFLET: '#fbf8f6',
				POSTER: '#e1f6eb',
				CHALLENGE: '#f2ecf9',
				CALL: '#dfe2ff',
				MAIL: '#ffe1ef',
				STATIC: '#f3edfa',
				OTHER: '#ffc3d9',
				SHARING: '#e5f8ff',
				GOTVCALLING: '#dfe2ff',
				GOTVCANVASSING: '#e1f6eb',
			},
			FIFTH: {
				CANVASSING: '#05b66f4d',
				REPORTING: '#c5c9f54d',
				EVENT: '#fbd6654d',
				LEAFLET: '#fbf8f64d',
				POSTER: '#05b66f4d',
				CHALLENGE: '#d6c2ed4d',
				CALL: '#94a0ff4d',
				MAIL: '#ff9bc94d',
				STATIC: '#d6c2ed4d',
				OTHER: '#ff387f4d',
				SHARING: '#e5f8ff4d',
				GOTVCALLING: '#94a0ff4d',
				GOTVCANVASSING: '#05b66f4d',
			},
			TEXT_MAIN: {
				CANVASSING: '#FFFFFF',
				REPORTING: '#c5c9f5',
				EVENT: '#222230',
				LEAFLET: '#222230',
				POSTER: '#FFFFFF',
				CHALLENGE: '#222230',
				CALL: '#222230',
				MAIL: '#222230',
				STATIC: '#222230',
				OTHER: '#222230',
				SHARING: '#222230',
				GOTVCALLING: '#222230',
				GOTVCANVASSING: '#FFFFFF',
			},
			TEXT_SECONDARY: {
				CANVASSING: '#FFFFFF',
				REPORTING: '#c5c9f5',
				EVENT: '#222230',
				LEAFLET: '#222230',
				POSTER: '#FFFFFF',
				CHALLENGE: '#222230',
				CALL: '#FFFFFF',
				MAIL: '#FFFFFF',
				STATIC: '#222230',
				OTHER: '#222230',
				SHARING: '#FFFFFF',
				GOTVCALLING: '#FFFFFF',
				GOTVCANVASSING: '#FFFFFF',
			},
			ICON_TEXT_MAIN: {
				CANVASSING: '#01b76e',
				REPORTING: '#c5c9f5',
				EVENT: '#f3b81f',
				LEAFLET: '#ff387e',
				POSTER: '#ff387e',
				CHALLENGE: '#9c67d9',
				CALL: '#93a0ff',
				MAIL: '#ff387e',
				STATIC: '#9c67d9',
				OTHER: '#a69388',
				SHARING: '#66c2e1',
				GOTVCALLING: '#222230',
				GOTVCANVASSING: '#222230',
			},
			MAIL: '#FBF8F6', // handout mail - boîtage
			EVENT: '#FFF6FB', // events - événements
			OTHER: '#FFF6FB', // other - autres
			CALL: '#EBEDFD', // calls - appel
			STATIC: '#F7F0FF', // direct conversation - rencontre
			CANVASSING: '#E1F6EB', // canvassing - porte à porte
			SHARING: '#E5F8FF', // sharing - partage

			REPORTING: '#B1C1F8', // reporting - reporting
			POSTER: '#ee564a', // {} - affiches
			GOTVCANVASSING: '#E1F6EB',
			GOTVCALLING: '#EBEDFD',
			// CHALLENGE: '#ee564a', // challenge - challenge
			// LEAFLET: '#1d2c8d', // leaflet - tractage
			DARKER: {
				MAIL: '#FF367F', // handout mail - boîtage
				EVENT: '#E1D1C6', // events - événements
				OTHER: '#FF367F', // other - autres
				CALL: '#94A0FF', // calls - appel
				STATIC: '#94A0FF', // direct conversation - rencontre
				CANVASSING: '#5BCD90', // canvassing - porte à porte
				SHARING: '#5BCD90', // sharing - partage
				GOTVCANVASSING: '#5BCD90',
				GOTVCALLING: '#94A0FF',
			},
		},
	},
	METABASE: {
		// VALID ON INTEGRATION ONLY
		HQ_URL: 'https://test.quorumapps.com/',
		URL: 'https://testmetabase.quorumapps.com',
		EN: {
			DASHBOARD: 110,
			QUESTIONS: {
				checkbox: 199,
				date: 200,
				radio: 201,
				range: 202,
				text: 203,
				photos: 199,
				signature: 199,
				numeric: 229,
			},
			DONATIONS_MEMBERSHIPS: 833,
		},
		FR: {
			DASHBOARD: 111,
			QUESTIONS: {
				checkbox: 195,
				date: 198,
				radio: 194,
				range: 197,
				text: 196,
				photos: 195,
				signature: 195,
				numeric: 228,
			},
			DONATIONS_MEMBERSHIPS: 782,
		},
	},
	APPLICATIONS: {
		IOS: 'https://apps.apple.com/fr/app/quorum-causes-campagnes/id1186778175',
		ANDROID: 'https://play.google.com/store/apps/details?id=com.quorumapps.quorum128598',
	},
	API_CONF: API_CONF,
	LOCAL_DEV_DATA_TEAM_MODE_ENABLED: process.env.LOCAL_DEV_DATA_TEAM === '1',
}

export { config as default }
export const MAP = config.MAP
export const ACTION = config.ACTION
export const LOCAL_DEV_DATA_TEAM_MODE_ENABLED = config.LOCAL_DEV_DATA_TEAM_MODE_ENABLED
export const API = config.API_CONF
export const METABASE = config.METABASE
export const APPLICATIONS = config.APPLICATIONS
