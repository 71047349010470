import communication_store from '../../models/communication_store'
import emailer_store from '../../models/emailer_store'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'

import {
	RESET_EMAILER_STORE,
	SET_BULK_EMAILS_SUBSCRIPTION,
	SET_EMAILER_ACCOUNT_DATA,
	SET_EMAILER_PRICES,
	SET_EMAILER_SENDERS,
	SET_EMAILER_TEMPLATES,
	SET_EMAILS_BALANCE,
	SET_EMAILS_CONTENT_CHARACTERS_COUNT,
	SET_EMAILS_OBJECT_LENGTH,
	SET_STATUS_POST_REGISTER_NEW_DOMAIN,
	SET_UPSELL_DOWNSELL_UPDATE_STATUS,
	UPDATE_BULK_EMAILS_SHOW_DETAILS,
	UPDATE_EMAILER_DOMAIN_LIST,
	UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS,
	UPDATE_GET_EMAILER_ACCOUNT_STATUS,
	UPDATE_IS_SENDING_SUCCEED,
	UPDATE_REMOVE_SELECTED_TEMPLATE_TRIGGER,
	SET_RECAP_DIALOG_VISIBILITY,
	SET_TEST_EMAIL_DIALOG_VISIBILITY,
	UPDATE_TEST_EMAIL_LIST,
	UPDATE_TEST_SENDING_STATUS,
	SET_EMAILER_SENDERS_LOADING_STATUS,
	SET_PROVIDER_CAMPAIGN_STATUSES,
	SET_IS_REPLY_TO_VALID,
	SET_USER_SENDERS,
} from './_mutation-types'

/**
 * @store @emailer
 * @namespaced
 * @description Mail's related info and store module
 */

const getValidatedEmailerSenders = (state) => {
	if (state.userSenders) {
		return state.userSenders.filter((sender) => sender.validated)
	}
	if (state.getEmailerAccountStatus === 'success') {
		return null
	}
	return null
}

const getProviderCampaignStatuses_Request = async (link, commit) => {
	let interval = null

	if (interval) clearInterval(interval)

	try {
		const initialRes = await communication_store.getProviderCampaignStatuses(link)
		commit(SET_PROVIDER_CAMPAIGN_STATUSES, initialRes?.steps)

		if (initialRes?.steps.every((step) => step.status === 'done')
			|| initialRes?.steps.some((step) => step.status === 'error')) {
			return
		}
	}
	catch (err) {
		console.error('Error while getting provider campaign statuses:', err)
		return
	}

	interval = setInterval(async () => {
		try {
			const res = await communication_store.getProviderCampaignStatuses(link)
			commit(SET_PROVIDER_CAMPAIGN_STATUSES, res?.steps)

			if (res?.steps.every((step) => step.status === 'done')
				|| res?.steps.some((step) => step.status === 'error')) {
				clearInterval(interval)
			}
		}
		catch (err) {
			console.error('Erreur while polling:', err)
			clearInterval(interval)
		}
	}, 750)

	setTimeout(() => {
		if (interval) clearInterval(interval)
	}, 150000)
}

const getProviderCampaignStatusesFormated = (providerCampaignStatuses) => {
	if (!providerCampaignStatuses) return null
	const campaignStepsWanted = ['create_brevo_list_into_qomon_audiences_folder', 'linking_emails_to_campaign', 'start_brevo_campaign']

	// // Uncomment to test locally
	// const mockup = [
	// 	{
	// 		order: 1,
	// 		name: 'format_template_with_attributes',
	// 		status: 'done',
	// 	},
	// 	{
	// 		order: 2,
	// 		name: 'qomon_audiences_brevo_folder',
	// 		status: 'done',
	// 	},
	// 	{
	// 		order: 3,
	// 		name: 'qomon_webhook',
	// 		status: 'done',
	// 	},
	// 	{
	// 		order: 4,
	// 		name: 'create_brevo_list_into_qomon_audiences_folder',
	// 		status: 'done',
	// 	},
	// 	{
	// 		order: 5,
	// 		name: 'import_contacts_into_brevo_list',
	// 		status: 'in_progress',
	// 	},
	// 	{
	// 		order: 6,
	// 		name: 'create_brevo_campaign',
	// 		status: 'in_progress',
	// 	},
	// 	{
	// 		order: 7,
	// 		name: 'linking_emails_to_campaign',
	// 		status: 'done',
	// 	},
	// 	{
	// 		order: 8,
	// 		name: 'start_brevo_campaign',
	// 		status: 'in_progress',
	// 	},
	// ]
	// return mockup.filter((step) => campaignStepsWanted.includes(step.name))

	return providerCampaignStatuses?.filter((step) => campaignStepsWanted.includes(step.name))
}

const getDefaultState = () => {
	return {
		emailsBalance: 0,
		contentCaractersCount: 0,
		objectLength: 0,
		showRecapDialog: false,
		showTestEmailDialog: false,
		isSendingSucceed: 'default',
		getEmailerAccountStatus: 'default',
		getEmailerAccountAndPricesStatus: 'default',
		upsellDownsellUpdateStatus: 'default',
		testEmailList: null,
		testSendingStatus: '',
		emailerAccount: {},
		emailerSenders: null,
		emailerSendersLoadingStatus: 'default',
		emailerAccountTemplates: null,
		emailerPrices: null,
		bulkEmailSubscription: null,
		showBulkEmailsDetails: true,
		removeSelectedTemplateTrigger: 0,
		statusRegisterNewDomain: 'init',
		providerCampaignStatuses: null,
		isReplyToValid: true,
		userSenders: null,
	}
}

const state = getDefaultState

const mutations = {
	[RESET_EMAILER_STORE]: (currentState) => {
		Object.assign(currentState, getDefaultState())
	},

	[SET_EMAILS_BALANCE]: (state, payload) => {
		if (!payload || payload === undefined) {
			state.emailsBalance = 0

			return
		}

		state.emailsBalance = payload
	},

	[SET_EMAILS_CONTENT_CHARACTERS_COUNT]: (state, payload) => {
		state.contentCaractersCount = payload
	},

	[SET_EMAILS_OBJECT_LENGTH]: (state, payload) => {
		state.objectLength = payload
	},

	[SET_IS_REPLY_TO_VALID]: (state, payload) => {
		state.isReplyToValid = payload
	},

	[SET_RECAP_DIALOG_VISIBILITY]: (state, payload) => {
		state.showRecapDialog = payload
	},

	[SET_TEST_EMAIL_DIALOG_VISIBILITY]: (state, payload) => {
		state.showTestEmailDialog = payload
	},

	[UPDATE_IS_SENDING_SUCCEED]: (state, payload) => {
		state.isSendingSucceed = payload
	},

	[UPDATE_TEST_EMAIL_LIST]: (state, payload) => {
		state.testEmailList = payload
	},

	[UPDATE_TEST_SENDING_STATUS]: (state, payload) => {
		state.testSendingStatus = payload
	},

	[SET_EMAILER_ACCOUNT_DATA]: (state, payload) => {
		if (!payload) return

		state.emailerAccount = payload
		state.emailerSenders = payload?.senders
	},

	[SET_EMAILER_TEMPLATES]: (state, payload) => {
		if (!payload) return (state.emailerAccountTemplates = [])

		state.emailerAccountTemplates = payload
	},

	[UPDATE_GET_EMAILER_ACCOUNT_STATUS]: (state, payload) => {
		state.getEmailerAccountStatus = payload
	},

	[UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS]: (state, payload) => {
		state.getEmailerAccountAndPricesStatus = payload
	},

	[SET_EMAILER_PRICES]: (state, payload) => {
		state.emailerPrices = payload
	},

	[SET_BULK_EMAILS_SUBSCRIPTION]: (state, payload) => {
		state.bulkEmailSubscription = payload
	},

	[SET_UPSELL_DOWNSELL_UPDATE_STATUS]: (state, payload) => {
		state.upsellDownsellUpdateStatus = payload
	},

	[UPDATE_BULK_EMAILS_SHOW_DETAILS]: (state) => {
		state.showBulkEmailsDetails = !state.showBulkEmailsDetails
	},

	[UPDATE_REMOVE_SELECTED_TEMPLATE_TRIGGER]: (state) => {
		state.removeSelectedTemplateTrigger += 1
	},

	[UPDATE_EMAILER_DOMAIN_LIST]: (state, payload) => {
		state.emailerAccount.domains = payload
		// state.tmpDomainList = payload
	},
	[SET_STATUS_POST_REGISTER_NEW_DOMAIN]: (state, payload) => {
		state.statusRegisterNewDomain = payload
	},

	[SET_EMAILER_SENDERS]: (state, payload) => {
		state.emailerSenders = payload
	},

	[SET_EMAILER_SENDERS_LOADING_STATUS]: (state, payload) => {
		state.emailerSendersLoadingStatus = payload
	},

	[SET_PROVIDER_CAMPAIGN_STATUSES]: (state, payload) => {
		state.providerCampaignStatuses = payload
	},

	[SET_USER_SENDERS]: (state, payload) => {
		state.userSenders = payload
	},
}

const actions = {
	actionSendEmailWithFilters: async ({ commit, state }, payload) => {
		if (state.testEmailList && state.testEmailList.length > 0) {
			payload.email.contacts = []
			state.testEmailList.forEach((email) => {
				payload.email.contacts.push({
					mail: email,
				})
			})
		}

		commit(UPDATE_TEST_EMAIL_LIST, null)

		return communication_store
			.sendEmailWithFilters(payload)
			.then((res) => {
				if (res?.data?.links?.length) {
					getProviderCampaignStatuses_Request(res?.data?.links[0].href, commit)
				}
				/// If it was a test, substract the cost from the balance locally
				if (payload.email?.contacts && payload.email?.contacts.length > 0) {
					commit(UPDATE_TEST_SENDING_STATUS, 'success')
					const cost = payload.email.cc
						? payload.email.contacts.length * 2
						: payload.email.contacts.length

					commit(SET_EMAILS_BALANCE, state.emailsBalance - cost)
					setTimeout(() => {
						commit(UPDATE_TEST_SENDING_STATUS, '')
					}, 1000)
				}
			})
			.catch((err) => {
				if (payload.email?.contacts && payload.email?.contacts.length > 0) {
					commit(UPDATE_TEST_SENDING_STATUS, 'error')
					setTimeout(() => {
						commit(UPDATE_TEST_SENDING_STATUS, '')
					}, 1000)
				}
			})
	},

	actionGetEmailsBalance: async ({ commit }, isElasticEmailActivated) => {
		return communication_store.getEmailsBalance(isElasticEmailActivated).then((res) => {
			if (isElasticEmailActivated) {
				commit(SET_EMAILER_ACCOUNT_DATA, res.account)
				commit(SET_EMAILS_BALANCE, res.balance)
			}
			else commit(SET_EMAILS_BALANCE, res)
		})
	},

	actionDeleteImageUploaded: async ({ commit, state }, payload) => {
		return communication_store.deletePhotoUploaded(payload)
	},

	actionSetEmailContentCharactersCount: async ({ commit }, payload) => {
		commit(SET_EMAILS_CONTENT_CHARACTERS_COUNT, payload)
	},

	actionSetEmailObjectLength: async ({ commit }, payload) => {
		commit(SET_EMAILS_OBJECT_LENGTH, payload)
	},

	actionSetIsReplyToValid: async ({ commit }, payload) => {
		commit(SET_IS_REPLY_TO_VALID, payload)
	},

	actionSetRecapDialogVisibility: async ({ commit }, payload) => {
		commit(SET_RECAP_DIALOG_VISIBILITY, payload)
	},

	actionSetTestEmailDialogVisibility: async ({ commit }, payload) => {
		commit(SET_TEST_EMAIL_DIALOG_VISIBILITY, payload)
	},

	actionUpdateIsSendingSucceed: async ({ commit }, payload) => {
		commit(UPDATE_IS_SENDING_SUCCEED, payload)
	},

	actionGetEmailerAccountStatus: async ({ commit }, payload) => {
		commit(UPDATE_GET_EMAILER_ACCOUNT_STATUS, payload)
	},

	actionSetEmailerAccountData: async ({ commit }) => {
		commit(UPDATE_GET_EMAILER_ACCOUNT_STATUS, 'loading')
		await emailer_store
			.getEmailerAccountData()
			.then((res) => {
				const domains = cloneDeep(res)

				commit(SET_EMAILER_ACCOUNT_DATA, domains)
				// commit(SET_EMAILER_ACCOUNT_DATA, res)
				commit(UPDATE_GET_EMAILER_ACCOUNT_STATUS, 'success')
			})
			.catch((err) => {
				console.log(err)
				commit(UPDATE_GET_EMAILER_ACCOUNT_STATUS, 'error')
			})
	},

	actionSetEmailsTemplates: async ({ commit }) => {
		await emailer_store
			.getEmailerAccountTemplates()
			.then((res) => {
				commit(SET_EMAILER_TEMPLATES, res)
			})
			.catch((err) => {
				console.log(err)
			})
	},

	actionSetEmailerAccountPricesAndSubscriptionData: async ({ commit, state, dispatch }) => {
		commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'loading')

		const getPrices = async () => {
			return await emailer_store
				.getPrices()
				.then((pricesRes) => {
					commit(SET_EMAILER_PRICES, pricesRes)
				})
				.catch((err) => {
					console.log(err)
					commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'error')
				})
		}

		const getBulkEmailsSubscription = async () => {
			return await emailer_store
				.getBulkEmailsSubscription()
				.then((subRes) => {
					commit(SET_BULK_EMAILS_SUBSCRIPTION, subRes)
				})
				.catch((err) => {
					console.log(err)
					commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'error')
				})
		}

		await dispatch('actionGetEmailsBalance', true)

		if (state.emailerAccount) {
			if (!state.emailerAccount?.subscription?.no_stripe) {
				await getPrices()
				await getBulkEmailsSubscription()

				if (state.emailerPrices && state.bulkEmailSubscription) {
					commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'success')

					return
				}

				commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'error')

				return
			}

			commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'success')

			return
		}

		commit(UPDATE_GET_EMAILER_ACCOUNT_AND_PRICES_STATUS, 'error')
	},

	actionCreateEmailerSender: async ({ commit, state }, payload) => {
		const sender = payload

		// if (sender?.validated == 'temp') delete sender['validated']
		// if (sender?.creation) delete sender['creation']
		// if (sender?.isEditing) delete sender['isEditing']
		if (!sender?.account_id) sender.account_id = state.emailerAccount.id

		emailer_store.createSender(sender).then((res) => commit(SET_EMAILER_ACCOUNT_DATA, res))
	},

	actionUpdateEmailerSender: async ({ commit, state }, payload) => {
		const sender = payload

		if (sender?.validated == 'temp') delete sender.validated

		if (sender?.creation) delete sender.creation

		if (sender?.isEditing) delete sender.isEditing

		if (!sender?.account_id) sender.account_id = state.emailerAccount.id

		emailer_store.updateSender(sender).then((res) => commit(SET_EMAILER_ACCOUNT_DATA, res))
	},

	actionUpdateEmailerSenders: async ({ commit, state }, payload) => {
		const modifiedSenders = payload.map((sender) => {
			if (sender?.validated == 'temp') delete sender.validated

			if (sender?.creation) delete sender.creation

			if (sender?.isEditing) delete sender.isEditing

			if (!sender?.account_id) sender.account_id = state.emailerAccount.id

			return sender
		})

		emailer_store
			.updateSenders(modifiedSenders)
			.then((res) => commit(SET_EMAILER_ACCOUNT_DATA, res))
	},

	actionDeleteEmailerSender: async ({ commit, dispatch, state }, payload) => {
		const sender = payload

		emailer_store.deleteSender(sender.id).then((res) => {
			commit(SET_EMAILER_ACCOUNT_DATA, res)
			const sendersActualized = [...res.senders]

			if (sender?.default_flag && sender.default_flag === true) {
				sendersActualized.forEach((aSender) => {
					if (!aSender?.removable) {
						aSender.default_flag = true
					}
				})
				dispatch('actionUpdateEmailerSenders', sendersActualized)
			}
		})
	},

	actionGetEmailerPrices: async ({ commit }) => {
		await emailer_store.getPrices().then((res) => {
			commit(SET_EMAILER_PRICES, res)
		})
	},

	actionUpdateEmailerAccount: async ({ commit }, emailer_account) => {
		return await emailer_store
			.updateEmailerAccount(emailer_account)
			.then((res) => {
				commit(SET_EMAILER_ACCOUNT_DATA, res)
			})
			.catch((err) => console.log(err))
	},

	actionUpdateEmailerCredits: async ({ commit, state }, payload) => {
		commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'loading')
		const newVolume = {
			quantity: payload.newVolume,
			last_refill: state.emailerAccount.credits_refill?.amount,
			refill_next: state.emailerAccount.credits_refill?.refill_next,
		}

		if (payload.isUpsell) {
			return await emailer_store
				.upsell(newVolume)
				.then((res) => {
					commit(SET_BULK_EMAILS_SUBSCRIPTION, res)
					commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'success')
				})
				.catch((err) => {
					console.log(err)

					if (err.status === 402) commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'payment_error')

					commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'error')
				})
		}

		return await emailer_store
			.downsell(newVolume)
			.then((res) => {
				commit(SET_BULK_EMAILS_SUBSCRIPTION, res)
				commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'success')
			})
			.catch((err) => {
				console.log(err)

				if (err.status === 402) commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'payment_error')

				commit(SET_UPSELL_DOWNSELL_UPDATE_STATUS, 'error')
			})
	},

	actionBeeAuth: async ({ commit, dispatch }, payload) => {
		await emailer_store
			.beeAuth(payload)
			.then((res) => {
				return res
			})
			.catch((err) => {
				console.log(err)
			})
	},

	actionCreateNewTemplate: async ({ commit }, payload) => {
		emailer_store
			.createEmailerAccountTemplate(payload)
			.then((res) => {
				commit(SET_EMAILER_TEMPLATES, res)

				return res
			})
			.catch((err) => {
				console.log(err)

				return err
			})
	},

	actionEditTemplate: async ({ commit }, template) => {
		emailer_store
			.editEmailerAccountTemplate(template)
			.then((res) => {
				commit(SET_EMAILER_TEMPLATES, res)

				return res
			})
			.catch((err) => {
				console.log(err)

				return err
			})
	},

	actionDeleteTemplate: async ({ commit }, payload) => {
		emailer_store
			.deleteEmailerAccountTemplate(payload)
			.then((res) => {
				commit(SET_EMAILER_TEMPLATES, res)

				return res
			})
			.catch((err) => {
				console.log(err)

				return err
			})
	},

	actionUpdateBulkEmailsShowDetails: async ({ commit }) => {
		commit(UPDATE_BULK_EMAILS_SHOW_DETAILS)
	},

	actionRemoveSelectedTemplateTrigger: async ({ commit }) => {
		commit(UPDATE_REMOVE_SELECTED_TEMPLATE_TRIGGER)
	},

	actionEmailDomainVerification: async ({ commit, state }, id) => {
		if (id) {
			const clonedDomains = cloneDeep(state.emailerAccount.domains)

			clonedDomains.forEach((aDomain) => {
				aDomain.loadingVerification = aDomain.id === id
			})

			commit(UPDATE_EMAILER_DOMAIN_LIST, clonedDomains)

			await emailer_store
				.getEmailDomainVerification(id)
				.then((res) => {
					const clonedDomains2 = cloneDeep(clonedDomains)
					const indexToSplice = findIndex(clonedDomains2, (aDomain) => aDomain.id === id)

					clonedDomains2.splice(indexToSplice, 1, res)
					commit(UPDATE_EMAILER_DOMAIN_LIST, clonedDomains2)
				})
				.catch(() => {
					commit(UPDATE_EMAILER_DOMAIN_LIST, state.emailerAccount.domains)
				})
		}
	},
	actionEmailCreateNewDomain: async ({ commit, dispatch }, payload) => {
		commit(SET_STATUS_POST_REGISTER_NEW_DOMAIN, 'loading')
		const { domain, account_id } = payload
		const finalPayload = {
			emailer_domain: {
				account_id,
				name: domain,
				custom_verp: `bounces.${domain}`,
			},
		}

		await emailer_store
			.registerNewDomain(finalPayload)
			.then(() => {
				commit(SET_STATUS_POST_REGISTER_NEW_DOMAIN, 'success')
				dispatch('actionSetEmailerAccountData')
			})
			.catch((err) => {
				commit(SET_STATUS_POST_REGISTER_NEW_DOMAIN, 'error')
				console.log(err)
			})
	},

	actionSendDomainVerificationInstruction: async ({ commit, state }, payload) => {

		// await emailer_store
		// 	.createNewDomain(payload)
		// 	.then((res) => {
		// 		commit(SET_EMAILER_ACCOUNT_DATA, cloneDeep(res))
		// 	})
		// 	.catch((err) => {
		// 		console.log(err)
		// 	})
	},

	actionResetAddADomainStatus: async ({ commit }) => {
		commit(SET_STATUS_POST_REGISTER_NEW_DOMAIN, 'init')
	},

	actionResetState: async ({ commit }) => {
		commit(RESET_EMAILER_STORE)
	},

	// Get senders from userConnected only
	actionGetSenders: async ({ commit }) => {
		commit(SET_EMAILER_SENDERS_LOADING_STATUS, 'loading')

		try {
			const res = await emailer_store.getSenders()

			if (!Array.isArray(res)) {
				commit(SET_USER_SENDERS, null)
				return
			}

			const normalizedSenders = res.map((aSender) => ({
				...aSender,
				default_flag: false,
			}))

			commit(SET_USER_SENDERS, normalizedSenders)
		}
		catch (error) {
			console.error('Error fetching senders:', error)
			commit(SET_USER_SENDERS, null)
		}
		finally {
			commit(SET_EMAILER_SENDERS_LOADING_STATUS, 'default')
		}
	},

	actionGetAllSenders: async ({ commit }) => {
		commit(SET_EMAILER_SENDERS_LOADING_STATUS, 'loading')

		try {
			const res = await emailer_store.getAllSenders()

			if (!Array.isArray(res)) {
				commit(SET_EMAILER_SENDERS, null)
				return
			}

			const normalizedSenders = res.map((aSender) => ({
				...aSender,
				default_flag: false,
				validated: 'validated' in aSender && aSender.validated === true,
			}))

			commit(SET_EMAILER_SENDERS, normalizedSenders)
		}
		catch (error) {
			console.error('Error fetching senders:', error)
		}
		finally {
			commit(SET_EMAILER_SENDERS_LOADING_STATUS, 'default')
		}
	},

	actionSetTestEmailList: async ({ commit }, payload) => {
		commit(UPDATE_TEST_EMAIL_LIST, payload)
	},

	actionGetEmailTmpThumbnail: async ({ commit }, payload) => {
		return await emailer_store.getEmailTmpThumbnail(payload).then((res) => {
			return res
		})
			.catch((err) => {
				console.log(err)
			})
	},
}

const getters = {
	getEmailsBalance: (state) => state.emailsBalance,
	getContentCaractersCount: (state) => state.contentCaractersCount,
	getObjectLength: (state) => state.objectLength,
	getIsReplyToValid: (state) => state.isReplyToValid,
	getIsRecapDialogVisible: (state) => state.showRecapDialog,
	getIsSendTestDialogVisible: (state) => state.showTestEmailDialog,
	isSendingSucceed: (state) => state.isSendingSucceed,
	getTestEmailList: (state) => state.testEmailList,
	getTestSendingStatus: (state) => state.testSendingStatus,
	getEmailerAccount: (state) => state.emailerAccount,
	getEmailerSubscription: (state) => state.bulkEmailSubscription,
	getEmailerSenders: (state) => (state.emailerSenders ? state.emailerSenders : null),
	getAllSenders: (state) => state.emailerSenders,
	getEmailerDomains: (state) => state.emailerAccount?.domains,

	getValidatedEmailerSenders: (state) => getValidatedEmailerSenders(state),
	getEmailerPrices: (state) => state.emailerPrices,
	getEmailerAccountStatus: (state) => state.getEmailerAccountStatus,
	getEmailerAccountAndPricesStatus: (state) => state.getEmailerAccountAndPricesStatus,
	getUpsellDownsellUpdateStatus: (state) => state.upsellDownsellUpdateStatus,
	getEmailerAccountTemplates: (state) => state.emailerAccountTemplates,
	getShowBulkEmailsDetails: (state) => state.showBulkEmailsDetails,
	getRemoveSelectedTemplateTrigger: (state) => state.removeSelectedTemplateTrigger,
	getStatusRegisterNewDomain: (state) => state.statusRegisterNewDomain,
	getEmailerSendersLoadingStatus: (state) => state.emailerSendersLoadingStatus,
	getProviderCampaignStatusesUnformated: (state) => state.providerCampaignStatuses,
	getProviderCampaignStatusesFormated: (state) => getProviderCampaignStatusesFormated(state.providerCampaignStatuses),
}

export const emailerModule = {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
